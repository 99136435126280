<!-- 招聘信息详情 -->
<template>
  <div class="wrapper pageBox">
    <!-- 顶部banner -->
    <div class="banner">
      <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/banner.png">
      <div class="bannerText">
        <div class="title">职位详情</div>
        <div class="btn">招聘资讯</div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="connect">
      <div class="back" @click="goBack">&lt;&lt; 返回招聘首页</div>
      <div class="conBox">
        <div class="top-info">
          <div class="info-position">{{ info.position }}</div>
          <div class="info-share">
            <img v-for="(item, index) in shareImgs" :key="index" :src="item" alt="">
          </div>
        </div>
        <div class="line" />
        <div class="info-content">
          <div class="info-time">发布时间：{{ info.createDate }}</div>
          <div class="content" v-html="info.content" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecruitmentInfo } from '@/service/api';
export default {
  name: 'RecruitmentInfo',
  components: {},
  data() {
    return {
      info: {},
      shareImgs: [
        require('../../assets/img/email.png'),
        require('../../assets/img/facebook.png'),
        require('../../assets/img/twitter.png'),
        require('../../assets/img/linkedin.png'),
        require('../../assets/img/googleplus.png'),
        require('../../assets/img/weibo.png'),
        require('../../assets/img/weixin.png')
      ]
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      getRecruitmentInfo(this.$route.query.id).then((res) => {
        this.info = res.recruitment;
      });
    },
    goBack() {
      this.$router.push({
        path: '/about/recruitment'
      });
    }
  }
};
</script>

<style lang='scss' scoped>
.banner {
  height: 400px;
  .btn {
    width: 98px !important;
    background: rgba(255, 255, 255, 0.15) !important;
    border-radius: 5px;
  }
  .title {
    font-size: 40px!important;
    font-weight: 400;
  }
}
.connect {
  width: 100%;
  height: 1000px;
  background: #fafafa;
  .back {
    cursor: pointer;
    width: 1280px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #6a6e77;
  }
  .conBox {
    width: 1280px;
    height: calc(100% - 40px);
    margin: 0 auto;
    background-color: #fff;
    overflow: hidden;
    .top-info {
      margin: 63px 20px 18px 139px;
      display: flex;
      justify-content: space-between;
      .info-position {
        font-size: 22px;
        font-weight: 400;
        color: #252b3a;
      }
      .info-share {
        display: flex;
        img {
          width: 18px;
          height: 17px;
          margin-left: 8px;
          margin-top: 16px;
          opacity: 0.66;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #dee0e5;
    }
    .info-content {
      margin: 22px 20px 18px 139px;
      .info-time {
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #9299a5;
        margin-bottom: 50px;
      }
    }
  }
}
</style>
